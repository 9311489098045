import { createSlice } from '@reduxjs/toolkit'
const initialData = () => {
    return []
}
const initialTitle = () => {
    return ""
}

const initialObject = () => {
    return {}
}

const initialPageSize = () => {
    return 100
}

const initialHideEmptyValues = ()=>{
    return false
}

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
      tableData: initialData(),
      dataTitle: initialTitle(),
      dataSubTitle: initialTitle(),
      dataLastLevelTitle: initialTitle(),
      titleParams:initialTitle(),
      tagsByClassData:initialData(),
      lastUpdatedDates:initialObject(),
      pageSize: initialPageSize(),
      hideEmptyValues: initialHideEmptyValues()
    },
    reducers: {
        handleData: (state, action) => {
            state.tableData = action.payload
        },
        handleTitleData: (state, action) => {
            state.dataTitle = action.payload
        },
        handleSubTitleData: (state, action) => {
            state.dataSubTitle = action.payload
        },
        handleLastLevelTitle: (state, action) => {
            state.dataLastLevelTitle = action.payload
        },
        handleTitleParams: (state, action) => {
            state.titleParams = action.payload
        },
        handleTagsByClassData: (state, action) => {
            state.tagsByClassData = action.payload
        },
        handleLastUpdatedDates: (state, action) => {
            state.lastUpdatedDates = action.payload
        },
        handlePageSize: (state, action) => {
            state.pageSize = action.payload
        },
        handleHideEmptyValues: (state, action) => {
            state.hideEmptyValues = action.payload
        },
        
    }
})
export const { handleData, handleTitleData, handleSubTitleData, handleLastLevelTitle,handleTitleParams,handleTagsByClassData,handleLastUpdatedDates,handlePageSize,handleHideEmptyValues} = dataSlice.actions

export default dataSlice.reducer