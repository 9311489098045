import { createSlice } from '@reduxjs/toolkit'
const initialData = () => {
    return false
}

export const loaderSlice = createSlice({
    name: 'data',
    initialState: {
      showLoader: initialData()
    },
    reducers: {
        handleLoader: (state, action) => {
            state.showLoader = action.payload
        },       
    }
})
export const { handleLoader} = loaderSlice.actions

export default loaderSlice.reducer